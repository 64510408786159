<template>
  <div class="page-user-login">
    <div class="my-page">
      <div class="top">
        <p>欢迎登录</p>
        <div>在游戏学习中见证宝贝的智慧成长</div>
      </div>
      <div class="login-box">
        <template v-if="roleType === '3'">
          <div class="input">
            <van-field
              v-model="UserName"
              label=""
              left-icon="contact"
              clearable
              placeholder="请输入用户名"
            />
          </div>
        </template>
        <div class="input">
          <van-field
            v-model="CellPhone"
            label=""
            left-icon="phone-circle-o"
            clearable
            placeholder="请输入您的手机号"
          />
        </div>
      </div>
      <van-button
        :loading="loading"
        type="primary"
        block
        color="#47AFA7"
        @click="next()"
        >登录</van-button
      >
      <!-- <div class="desc" @click="goChoseRole">
        还未绑定？
        <span>&lt; 点击绑定 &gt;</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Button, Field, Toast } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Toast.name]: Toast
  },
  data () {
    return {
      userInfo: JSON.parse(window.localStorage.getItem('userInfo')),
      wxuserInfo: JSON.parse(window.localStorage.getItem('wxuserInfo')),
      roleType: null,
      UserName: '',
      CellPhone: '',
      loading: false
    }
  },
  mounted () {
    this.roleType = this.$route.query.roleType
  },
  methods: {
    goChoseRole () {
      this.$router.push('ChoseRole')
    },
    next () {
      // 先校验必填项

      if (this.roleType === '2' || this.roleType === '4') {
        if (!this.CellPhone) {
          Toast.fail('请输入手机号')
          return
        }
      } else if (this.roleType === '3') {
        if (!this.UserName) {
          Toast.fail('请输入用户名')
          return
        }
        if (!this.CellPhone) {
          Toast.fail('请输入手机号')
          return
        }
      }
      // 发起登录请求
      this.login({
        UserName: this.UserName,
        CellPhone: this.CellPhone,
        UserType: this.roleType,
        OpenId: this.wxuserInfo.OpenID,
        HeadUrl: this.wxuserInfo.headUrl
      })
    },
    login (params) {
      this.loading = true
      // /api/Account/Login
      // /api/WeChat/LoginTest
      this.$axios.post('/api/User/Register', params).then(res => {
        this.loading = false
        if (res.code === 200) {
          Toast.success(res.msg || '登录成功')
          this.afterLogin(res.data.loginUser,res.data.IsAdministration)
        } else {
          Toast.fail(res.msg)
        }
      })
    },
    afterLogin (info,IsAdministration) {
      let that = this
      // 存储Token
      window.localStorage.setItem('TOKEN', info.Token)
      window.localStorage.setItem(
        "IsAdministration",
        IsAdministration
      );
      // 存储UserType 1 学生，2职工，3家长
      window.localStorage.setItem('UserType', info.UserType)
      // 存储Token
      window.localStorage.setItem('SchoolID', info.ReturnSchoolID)
      // 存储用户信息
      window.localStorage.setItem('userInfo', JSON.stringify(info))
      setTimeout(() => {
        if (this.roleType === '4') {
          that.$router.push('HomeEmpty')
        } else {
          that.$router.push('Home')
        }
      }, 1500)
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
